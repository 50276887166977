<template lang="pug">
section(:class='{ "group--bordered": bordered, "group--highlight": highlight }' class='group')
  label(class='group__label')
    | {{ label }}
  div
    slot
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const Group = defineComponent({
  props: {
    label: String,
    highlight: Boolean,
    bordered: Boolean,
  },
  setup() {},
})
export default Group
</script>
<style lang="stylus">
@import '../styles/variables.styl'
:root
  --group-border-color: #eee
  --group-label-color: #ccc

.group
  &--highlight
    --group-border-color: var(--color-nuucon)
    --group-label-color: var(--color-nuucon)

  &__label
    display: inline-block
    padding: 10px 0px 20px
    font-size: 13px
    letter-spacing: 1.5px
    text-transform: uppercase
    font-weight: 300
    color: var(--group-label-color)!important
    ~/--bordered &
      background: #fff
      position: relative
      top: -10px
      padding: 0 6px
      left: -6px
  &--bordered
    border: 1px solid var(--group-border-color)
    border-radius: 6px
    padding: 0 20px 20px
</style>
